.root {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #1a171e;
  color: #777;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
