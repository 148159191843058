.root {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #1a171e;
  display: flex;
  flex-direction: column;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: space-evenly;
  padding: 20px;
}
.logoImg {
}
.logoText {
  font-size: 24px;
  font-weight: 700;
  color: #7b3434;
  align-self: center;
  text-align: center;
}
.title {
  color: #43413d;
  margin: 5px 20px;
}
.hr {
  border: 0.5px solid #565050;
  height: 0px;
  width: 100%;
}
.list {
  margin-right: 0px;
  margin-left: 30px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style-type: none;
  cursor: pointer;
}
.listItem {
  text-align: start;
}
