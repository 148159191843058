.root {
  display: flex;
  justify-items: end;
  justify-content: space-between;
  border: 2px solid #524b4b;
  border-radius: 15px;
  overflow: hidden;
}
.input {
  width: 50%;
  background-color: transparent;
  padding: 10px;
  font-size: 18px;
  border: 0px dotted #faf7f7;
  overflow: hidden;
  color: white;
  outline: none;
}
.button {
  background-color: rgb(198, 120, 120);
  color: white;
  font-weight: 500;
  padding: 15px;
  border: 0px solid #a18d8d;
  cursor: pointer;
}
