.root {
  width: 250px;
  height: 350px;
  border-radius: 10;
  margin-bottom: 30px;
  position: relative;
}
.cardImg {
  width: 100%;
  height: 100%;
}
.detail {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    #000000 0%,
    rgba(117, 117, 117, 0.5393258426966292) 41%,
    rgba(255, 255, 255, 0) 89%
  );
  background-position: 0%;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  color: white;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
.rating {
  font-weight: 500;
}
.desc {
  font-weight: 300;
}
.button {
  border-radius: 50px;
  background-color: #b75d5d;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 30px;
  border: 0px solid black;
}
