.root {
  width: 250px;
  height: 350px;
  border-radius: 10;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.cardImg {
  width: 100%;
}
.detail {
  width: 100%;

  background-color: w;
  background-image: none;
  background-position: 0%;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  opacity: 1;
  &:hover {
    opacity: 1;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #4c4646;
  justify-content: space-between;
}
.rating {
  font-weight: 500;
}
.desc {
  font-weight: 300;
}
.button {
  border-radius: 50px;
  background-color: #b75d5d;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 30px;
  border: 0px solid black;
}
.title {
  opacity: 1;
  color: #433b3b;
}
.delete {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #000000 0%,
    rgba(77, 77, 77, 0.7) 30%,
    rgba(255, 255, 255, 0) 100%
  );
  background-color: transparent;
  background-position: 0%;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  align-self: center;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
.deleteImg {
  width: 30px;
  height: 30px;
}
